.top-video {
  position: relative;
  height: calc(100vh - 100px);
  min-height: 700px;
  video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $blue;
    opacity: 0.5;
    z-index: -1;
  }
  &__row {
    position: relative;
  }
  &__logo {
    margin-bottom: 20px;
  }
  &__svg {
    right: -125px;
    bottom: 97px;
    background-image: url("data:image/svg+xml,%3Csvg width='365' height='59' viewBox='0 0 365 59' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M281.5 4.99995C282.801 4.99995 283.87 4.00631 283.989 2.73658L336.396 2.73657C351.157 2.73657 363.123 14.7028 363.123 29.4638C363.124 44.2249 351.157 56.1911 336.396 56.1911L4.98111 56.1911C4.82893 54.9561 3.77613 54 2.50001 54C1.1193 54 9.63736e-06 55.1193 9.87877e-06 56.5C1.01202e-05 57.8807 1.1193 59 2.50001 59C3.64098 59 4.60343 58.2357 4.90326 57.1911L336.396 57.1911C351.71 57.1911 364.124 44.7772 364.123 29.4638C364.123 14.1505 351.71 1.73657 336.396 1.73657L283.881 1.73658C283.559 0.729216 282.615 -4.94139e-05 281.5 -4.9219e-05C280.119 -4.89776e-05 279 1.11924 279 2.49995C279 3.88066 280.119 4.99995 281.5 4.99995Z' fill='white'/%3E%3C/svg%3E%0A");
    width: 365px;
    height: 59px;
  }
  .wrapper {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__text-bg {
    font-size: 36px;
    background-color: $white;
    color: $blue;
    font-weight: 400;
    padding: 3px 20px;
    border-radius: 5px;
    margin: 10px 0px;
  }
  &__contact {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .tel {
    font-size: 30px;
    margin-right: 35px;
    color: #fff;
    .icon {
      width: 50px;
      height: 50px;
      filter: brightness(0) invert(1);
    }
  }
}
.products {
  z-index: 1;
  position: relative;
  background-color: #f2f2f2;
  padding-top: 0;
  padding-bottom: 150px;
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    transform: translateY(-99%);
  }
  .title-row {
    width: fit-content;
    margin-bottom: 75px;
    p {
      max-width: 280px;
    }
  }
  .title-line {
    background-image: url("data:image/svg+xml,%3Csvg width='245' height='59' viewBox='0 0 245 59' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M82.623 4.99999C81.3221 4.99999 80.2532 4.00635 80.134 2.73662L27.7268 2.73661C12.9657 2.73661 0.999526 14.7028 0.999524 29.4639C0.999432 44.2249 12.9656 56.1911 27.7267 56.1911L239.142 56.1911C239.294 54.9561 240.347 54 241.623 54C243.004 54 244.123 55.1193 244.123 56.5C244.123 57.8807 243.004 59 241.623 59C240.482 59 239.52 58.2357 239.22 57.1911L27.7267 57.1911C12.4133 57.1911 -0.00056849 44.7772 -0.000475598 29.4639C-0.00047426 14.1505 12.4134 1.73661 27.7268 1.73661L80.2417 1.73662C80.5643 0.729251 81.5085 -1.42162e-05 82.623 -1.41188e-05C84.0037 -1.39981e-05 85.123 1.11927 85.123 2.49999C85.123 3.8807 84.0037 4.99999 82.623 4.99999Z' fill='%23153862'/%3E%3C/svg%3E%0A");
  }
  &__row {
    margin: -30px;
  }
  .item-product {
    margin: 30px;
    width: calc(16.66666% - 60px);
  }
}
.production {
  padding: 127px 0px;
  position: relative;

  &__img-work {
    position: absolute;
    right: 0;
    bottom: -84px;
    z-index: 1;
  }
  .wrapper {
    position: relative;
    padding-bottom: 148px;
    &::after {
      content: '';
      position: absolute;
      background: #f2f2f2;
      border-radius: 10px;
      width: auto;
      height: 418px;
      left: -186px;
      right: -186px;
      bottom: 0;
    }
  }
  &__swiper {
    padding-top: 44px;
  }

  .wrap-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
  .swiper-slide {
    display: grid;
    grid-template-columns: 0.92fr 2fr;
    grid-template-rows: 1fr 1fr;
    gap: 30px;
    height: 370px;
    width: 540px;
    grid-template-areas:
      'a c'
      'b c';
    .item-slider {
      border-radius: 10px;
      overflow: hidden;

      &.item-slider-img {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &.item-slider-text {
        padding: 30px;
        background-color: #ba4921;
        color: #fff;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        img {
          width: 33px;
          height: 33px;
          object-fit: contain;
          margin-bottom: 20px;
          margin-left: auto;
          margin-right: 0;
        }
        ul {
          padding: 0;
          margin: 0;
          li {
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &:nth-child(1) {
        grid-area: a;
      }
      &:nth-child(2) {
        grid-area: b;
      }
      &:nth-child(3) {
        grid-area: c;
      }
    }
  }
}
.for-you {
  position: relative;
  padding-bottom: 80px;
  margin-bottom: 150px;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 373px;
    background: #153862;
    z-index: -1;
  }
  .title-row {
    max-width: 535px;
    margin-bottom: 60px;
  }
  .title-line {
    background-image: url("data:image/svg+xml,%3Csvg width='245' height='59' viewBox='0 0 245 59' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M161.5 4.99999C162.801 4.99999 163.87 4.00635 163.989 2.73662L216.396 2.73661C231.157 2.73661 243.124 14.7028 243.124 29.4639C243.124 44.2249 231.157 56.1911 216.396 56.1911L4.98111 56.1911C4.82893 54.9561 3.77612 54 2.5 54C1.11929 54 4.81868e-06 55.1193 4.93939e-06 56.5C5.06009e-06 57.8807 1.11929 59 2.50001 59C3.64097 59 4.60342 58.2357 4.90326 57.1911L216.396 57.1911C231.71 57.1911 244.124 44.7772 244.124 29.4639C244.124 14.1505 231.71 1.73661 216.396 1.73661L163.881 1.73662C163.559 0.729251 162.615 -1.42162e-05 161.5 -1.41188e-05C160.119 -1.39981e-05 159 1.11927 159 2.49999C159 3.8807 160.119 4.99999 161.5 4.99999Z' fill='%23153862'/%3E%3C/svg%3E%0A");
  }
  &__list {
    width: calc(100% - 695px);
    padding-right: 60px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        position: relative;
        padding-left: 35px;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $blue;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:nth-child(2) ~ li {
          color: #fff;
          &::after {
            background-color: #fff;
          }
        }
        span {
          margin-left: 30px;
        }
        img {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
  &__video {
    width: 695px;
    video {
      width: 100%;
      height: 408px;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}
.orders {
  padding-top: 97px;
  background: #f2f2f2;
  padding-bottom: 80px;
  position: relative;
  .icons-row {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    justify-content: center;
    img {
      margin: 0px 7px;
    }
  }
  &__swiper-grid-mobile {
    display: none;
  }
  &__swiper-grid-desctop {
    position: relative;
    padding-top: 40px;
    margin-top: -10px;
    .wrap-btn {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .title-row {
    text-align: center;
    h2 {
      margin-bottom: 15px;
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(4, 160px);
    gap: 30px;
    grid-template-areas:
      'q q q q q q w w w w w w e e e e r r r r r r r r r'
      'q q q q q q t t t t y y y y y y r r r r r r r r r'
      'u u u u u u u u u i i i i i i o o o o o o p p p p'
      'u u u u u u u u u i i i i i i a a a a a a a a a a';
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .q {
    grid-area: q;
  }
  .w {
    grid-area: w;
  }
  .e {
    grid-area: e;
  }
  .r {
    grid-area: r;
  }
  .t {
    grid-area: t;
  }
  .y {
    grid-area: y;
  }
  .u {
    grid-area: u;
  }
  .i {
    grid-area: i;
  }
  .o {
    grid-area: o;
  }
  .p {
    grid-area: p;
  }
  .a {
    grid-area: a;
  }
  .btn {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }
}
.form-section {
  position: relative;
  padding: 200px 0;
  .image-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 620px;
    width: 45%;
    max-width: 880px;
    border-radius: 10px 0px 0px 10px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .image-center {
    position: absolute;
    border-radius: 10px;
    right: calc(50% - 100px);
    transform: translateX(50%);
    top: 170px;
    width: 315px;
    height: 210px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .title-row {
    margin-bottom: 35px;
    p {
      max-width: 355px;
      font-size: 14px;
    }
    &__img {
      position: absolute;
      top: 32px;
      right: 30px;
      width: 59px;
      height: 204px;
      background-image: url("data:image/svg+xml,%3Csvg width='59' height='204' viewBox='0 0 59 204' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M29.5364 1.00003C44.2975 1.00003 56.2637 12.9662 56.2637 27.7273L56.2637 82.5346L57.2637 82.5346L57.2637 27.7273C57.2637 12.414 44.8498 2.99749e-05 29.5364 2.93056e-05C14.2231 -6.29165e-05 1.80917 12.4139 1.80918 27.7272L1.80918 200.123L2.80918 200.123L2.80918 27.7272C2.80917 12.9661 14.7754 0.999937 29.5364 1.00003Z' fill='%23153862'/%3E%3Cpath d='M56.5 80.1234C55.1193 80.1234 54 81.2427 54 82.6234C54 84.0041 55.1193 85.1234 56.5 85.1234C57.8807 85.1234 59 84.0041 59 82.6234C59 81.2427 57.8807 80.1234 56.5 80.1234Z' fill='%23153862'/%3E%3Ccircle r='2.5' transform='matrix(4.37114e-08 -1 -1 -4.37114e-08 2.5 200.623)' fill='%23153862'/%3E%3C/svg%3E%0A");
    }
  }
  &__col-form {
    max-width: 540px;
    width: 100%;
  }
  .form-idea {
    width: 350px;
  }
  &__contact {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 35px;
    p {
      margin: 0;
      max-width: 275px;
      font-size: 14px;
    }
    a {
      color: inherit;
    }
  }
}
.info {
  padding: 96px 0px 155px;
  background: #f2f2f2;
  h2 {
    margin: 0;
    margin-bottom: 0;
  }
  &__swiper {
    padding-top: 50px;
  }
  .swiper-slide {
    width: fit-content;
  }
  .wrap-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.last-section {
  padding-bottom: 20px;
  text-align: center;
  .title-row {
    margin-bottom: 42px;
    margin-top: 64px;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  img {
    margin-left: auto;
    margin-right: auto;
  }
  &__img {
    width: 350px;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__icon {
    margin-top: -55px;
  }
  .title-line {
    background-image: url("data:image/svg+xml,%3Csvg width='204' height='59' viewBox='0 0 204 59' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M120.5 5C121.801 5 122.87 4.00636 122.989 2.73663L175.396 2.73663C190.157 2.73663 202.123 14.7028 202.123 29.4639C202.123 44.2249 190.157 56.1911 175.396 56.1911L4.9811 56.1911C4.82893 54.9562 3.77612 54 2.49999 54C1.11928 54 -9.44166e-06 55.1193 -9.44166e-06 56.5C-9.44166e-06 57.8807 1.11928 59 2.49999 59C3.64095 59 4.60339 58.2357 4.90323 57.1911L175.396 57.1911C190.71 57.1911 203.123 44.7772 203.123 29.4639C203.123 14.1506 190.709 1.73663 175.396 1.73663L122.881 1.73663C122.559 0.729265 121.615 0 120.5 0C119.119 0 118 1.11929 118 2.5C118 3.88071 119.119 5 120.5 5Z' fill='%23153862'/%3E%3C/svg%3E%0A");
    width: 204px;
    height: 59px;
  }
}
