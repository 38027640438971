.not-found {
  &__row {
    justify-content: space-between;
    align-items: center;
  }
  &__img {
    width: 540px;
    height: 400px;
    object-fit: cover;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  &__text {
    p {
      max-width: 220px;
      margin-left: auto;
      margin-right: 25px;
    }
  }
  h1 {
    font-family: $title;
    font-size: 150px;
    line-height: 175px;
    margin: 0;
    width: fit-content;
    margin-left: auto;
    margin-right: 115px;
    span {
      color: #ba4921;
    }
  }
  .btn {
    margin-top: 40px;
  }
  .title-row {
    width: 520px;
    &__img {
      bottom: 40px;
    }
  }
  .title-line {
    left: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='245' height='59' viewBox='0 0 245 59' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M82.6234 4.99997C81.3225 4.99997 80.2537 4.00633 80.1345 2.7366L27.7273 2.73659C12.9662 2.73659 1.00001 14.7028 1.00001 29.4638C0.999916 44.2249 12.9661 56.1911 27.7272 56.1911L239.142 56.1911C239.295 54.9561 240.347 54 241.624 54C243.004 54 244.124 55.1193 244.124 56.5C244.124 57.8807 243.004 59 241.624 59C240.483 59 239.52 58.2357 239.22 57.1911L27.7272 57.1911C12.4138 57.1911 -8.4123e-05 44.7772 1.01072e-05 29.4638C1.27846e-05 14.1505 12.4139 1.73659 27.7273 1.73659L80.2421 1.7366C80.5648 0.729236 81.509 -2.84324e-05 82.6234 -2.82376e-05C84.0042 -2.79962e-05 85.1234 1.11926 85.1234 2.49997C85.1234 3.88068 84.0041 4.99997 82.6234 4.99997Z' fill='%23153862'/%3E%3C/svg%3E%0A");
  }
}
.not-found + .footer {
  .btn-circle {
    display: none;
  }
}
