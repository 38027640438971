.article {
  font-size: 16px;
  line-height: 1.4;
  padding-top: 10px;
  margin-bottom: 95px;
  &__wrap {
    max-width: 730px;
    margin-left: auto;
    margin-right: auto;
  }
  img {
    border-radius: 10px;
  }
  h1,
  h2,
  h3,
  h4 {
    text-align: center;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
  .wrapper {
    position: relative;
    padding-bottom: 95px;
  }
  .back-btn {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.image-parallax {
  display: flex;
  justify-content: space-between;
  margin: 95px 0px;
  > div {
    height: 100%;
  }
  &__horizontal {
    position: relative;
    .image-parallax__bg {
      position: absolute;
      width: 445px;
      height: 400px;
      top: 50%;
      transform: translateY(-50%);
      left: -55px;
    }
    .img-wrap {
      width: 445px;
      height: 290px;
    }
  }
  &__vertical {
    position: relative;
    .img-wrap {
      width: 255px;
      height: 345px;
    }
    .image-parallax__bg {
      position: absolute;
      width: 255px;
      height: 345px;
      top: auto;
      bottom: -55px;
      left: auto;
      right: -55px;
    }
  }

  .img-wrap {
    border-radius: 10px;
    overflow: hidden;
    img {
      transition: all 0.6s ease;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__bg {
    position: absolute;
    background: #f2f2f2;
    border-radius: 10px;
    z-index: -1;
  }
}
