.popup {
  width: 100%;
  max-width: 730px;
  padding: 40px;
  background: #f2f2f2;
  box-shadow: 5px 15px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  &__form {
    max-width: 350px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    .title-form {
      margin-bottom: 15px;
    }
    h4 {
      margin: 0;
      margin-bottom: 10px;
    }
    p {
      font-size: 12px;
      line-height: 15px;
    }
  }
  .contact {
    text-align: center;
    margin-top: 19px;
    a {
      color: inherit;
    }
    .soc {
      justify-content: center;
      margin-top: 14px;
    }
  }
  .form-idea {
    .btn {
      margin-top: 10px;
    }
  }
}
#popup-thank {
  text-align: center;
  h4 {
    margin: 0;
    margin-bottom: 20px;
  }
  .btn {
    margin-left: auto;
    margin-right: auto;
    margin-top: 48px;
    width: 100%;
    max-width: 220px;
  }
}
.mfp-close-btn-in .mfp-close {
  color: #153862;
  opacity: 1;
  font-size: 38px;
}
.mfp-bg {
  background: rgba(21, 56, 98, 0.5);
  backdrop-filter: blur(5px);
  opacity: 1;
}
