body {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    font-size: 18px;
    background-color: #fff;
    color: $blue;
}

.wrapper {
    width: 100%;
    max-width: 1110px;
    margin-left: auto;
    margin-right: auto;
}

.wrapper__full {
    width: 100%;
    max-width: 1920px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.mobile-menu {
    display: none;
}

.header {
    &.header-not-home {
        .header__bot {
            background: #f2f2f2;
        }
    }
    &__mob {
        display: none;
    }
    &__top {
        background-color: $blue;
        padding: 8px 0px;
        .row {
            justify-content: flex-end;
            align-items: center;
        }
        .link-text {
            color: $white;
            font-size: 12px;
            margin-right: 40px;
        }
    }
    &__bot {
        padding: 16px 0px;
        .tel {
            font-size: 18px;
            color: $blue;
            margin-right: 30px;
        }
        .row {
            justify-content: space-between;
        }
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.soc {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        &+li {
            margin-left: 10px;
        }
    }
    .icon {
        width: 38px;
        height: 33px;
    }
}

.lang {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: $white;
    li {
        margin: 0px 4px;
    }
    span,
    a {
        text-decoration: none;
        color: inherit;
        opacity: 0.5;
    }
    span {
        opacity: 1;
    }
    a {
        &:hover {
            opacity: 1;
        }
    }
}

.link-text {
    text-decoration: none;
    color: inherit;
}

.tel {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    .icon {
        margin-right: 8px;
    }
    &:hover {
        .icon {
            animation-duration: 0.4s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: jittery;
        }
    }
}

.shake .icon:hover {
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: jittery;
}

@keyframes jittery {
    10% {
        transform: translate(-1px, -1px);
    }
    20% {
        transform: translate(-1px, 1px);
    }
    30% {
        transform: translate(1px, -1px);
    }
    40% {
        transform: translate(1px, 1px);
    }
    50% {
        transform: translate(1px, -1px);
    }
    60% {
        transform: translate(-1px, 1px);
    }
    70% {
        transform: translate(-1px, -1px);
    }
    80% {
        transform: translate(-1px, 1px);
    }
    90% {
        transform: translate(-1px, -1px);
    }
    100% {
        transform: translate(1px, -1px);
    }
}

h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    font-family: $title;
}

h3 {
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    font-family: $title;
}

h4 {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    font-family: $title;
}

.title-row {
    position: relative;
    h2 {
        margin: 0;
        margin-bottom: 8px;
    }
    h3 {
        margin: 0;
        margin-bottom: 8px;
    }
    p {
        margin: 0;
    }
    &.title-right {
        .title-row__img {
            left: -150px;
            top: 30px;
            bottom: auto;
        }
    }
    &__img {
        position: absolute;
        bottom: 0;
        right: -131px;
    }
}

.title-line {
    position: absolute;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 245px;
    height: 59px;
    pointer-events: none;
}

.title-right {
    margin-left: auto;
    margin-right: 0;
    p {
        margin-left: auto;
        margin-right: 0;
    }
}

.item-product {
    text-align: center;
    img {
        margin-bottom: 10px;
        width: 100%;
        height: auto;
    }
}

.swiper-button-next,
.swiper-button-prev {
    width: 21px;
    height: 21px;
    margin: 0;
    position: static;
    &::after {
        font-size: 0;
        width: 21px;
        height: 21px;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
}

input:not([type='checkbox'], [type='radio']) {
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid #153862;
    padding: 10px 0px;
    font-size: 16px;
    color: #153862;
    background-color: transparent;
    &::placeholder {
        color: inherit;
        font-family: inherit;
    }
    &.error {
        border-color: #ba4921;
        color: #ba4921;
    }
}

.swiper-button-next {
    &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='13' viewBox='0 0 22 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.7878 0.574525C15.6901 0.476851 15.5576 0.421977 15.4195 0.421976C15.2813 0.421977 15.1489 0.476851 15.0512 0.574525C14.9535 0.6722 14.8986 0.804675 14.8986 0.942808C14.8986 1.08094 14.9535 1.21342 15.0512 1.31109L19.6126 5.8725L1.13009 5.8725C0.991941 5.8725 0.859448 5.92738 0.761761 6.02507C0.664073 6.12275 0.609193 6.25525 0.609193 6.3934C0.609193 6.53155 0.664072 6.66404 0.76176 6.76173C0.859448 6.85942 0.99194 6.9143 1.13009 6.9143L19.6126 6.9143L15.0512 11.4757C14.9535 11.5734 14.8986 11.7059 14.8986 11.844C14.8986 11.9821 14.9535 12.1146 15.0512 12.2123C15.1489 12.3099 15.2813 12.3648 15.4195 12.3648C15.5576 12.3648 15.6901 12.3099 15.7878 12.2123L21.2383 6.76168C21.4416 6.55839 21.4416 6.22841 21.2383 6.02512L15.7878 0.574525Z' fill='%23153862'/%3E%3C/svg%3E%0A");
    }
}

.swiper-button-prev {
    &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='13' viewBox='0 0 22 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='1%0A' d='M6.2123 12.4255C6.30998 12.5231 6.44245 12.578 6.58058 12.578C6.71872 12.578 6.85119 12.5231 6.94887 12.4255C7.04654 12.3278 7.10142 12.1953 7.10142 12.0572C7.10142 11.9191 7.04654 11.7866 6.94887 11.6889L2.38746 7.1275L20.87 7.1275C21.0081 7.1275 21.1406 7.07262 21.2383 6.97493C21.336 6.87725 21.3909 6.74475 21.3909 6.6066C21.3909 6.46845 21.336 6.33596 21.2383 6.23827C21.1406 6.14058 21.0081 6.0857 20.87 6.0857L2.38746 6.0857L6.94887 1.5243C7.04654 1.42662 7.10142 1.29414 7.10142 1.15601C7.10142 1.01788 7.04654 0.885404 6.94887 0.787729C6.85119 0.690054 6.71872 0.635181 6.58058 0.635181C6.44245 0.635181 6.30998 0.690054 6.2123 0.787729L0.761712 6.23832C0.558419 6.44161 0.55842 6.77159 0.761712 6.97488L6.2123 12.4255Z' fill='%23153862'/%3E%3C/svg%3E%0A");
    }
}

.wrap-btn {
    display: flex;
    align-items: center;
    width: 60px;
    justify-content: space-between;
}

.pin-top,
.pin-bot {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.pin-top {
    top: -36px;
}

.pin-bot {
    bottom: -36px;
}

.btn {
    height: 45px;
    border-radius: 45px;
    font-weight: 400;
    font-size: 18px;
    padding: 0px 40px;
    border: none;
    outline: none;
    text-decoration: none;
    color: #153862;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #153862;
    transition: all 0.3s ease;
    &-primary {
        color: #fff;
        background-color: #153862;
        &:hover {
            color: #153862;
            background-color: transparent;
            svg {
                path {
                    fill: $blue;
                }
            }
        }
    }
    &-outline {
        &:hover {
            color: #fff;
            background-color: #153862;
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
    svg {
        margin-right: 15px;
    }
    svg {
        width: 21px;
        height: 21px;
        margin-right: 15px;
        transition: all 0.3s ease;
        path {
            transition: all 0.3s ease;
        }
    }
}

.form-idea {
    input {
        margin-bottom: 20px;
    }
    .btn {
        width: 100%;
        margin-top: 30px;
    }
}

.item-info {
    background-color: #fff;
    padding: 40px;
    width: 445px;
    border-radius: 10px;
    &__title {
        margin: 0;
        font-size: 21px;
        line-height: 26px;
        display: -webkit-box;
        -webkit-line-clamp: 3; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 10px;
        height: 78px;
        color: #153862;
        text-decoration: none;
    }
    &__img {
        border-radius: 10px;
        width: 100%;
        height: 250px;
        overflow: hidden;
        margin-bottom: 18px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .btn {
        margin-left: auto;
        margin-right: 0;
    }
}

.footer {
    position: relative;
    height: 750px;
    display: flex;
    align-items: flex-end;
    color: #f2f2f2;
    background-image: url(../img/footer-bg.svg);
    background-size: cover;
    background-position: top;
    flex-direction: column;
    justify-content: space-between;
    .wrapper {
        position: relative;
    }
    .btn-circle {
        position: absolute;
        left: 0;
        top: 0;
    }
    .bg {
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 0;
        z-index: -1;
    }
    &__row {
        justify-content: center;
        text-align: center;
    }
    &__contact {
        display: flex;
        flex-direction: column;
        a {
            text-decoration: none;
            color: inherit;
            font-size: 21px;
            font-weight: 300;
            line-height: 29px;
        }
    }
    .soc {
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 95px;
        .icon {
            width: 27px;
            height: 27px;
        }
        li {
            margin: 0px 15px;
        }
    }
    &__bot {
        display: flex;
        justify-content: space-between;
        padding-bottom: 42px;
        font-size: 14px;
        >span {
            opacity: 0.6;
        }
    }
    &__bot-links {
        a {
            color: inherit;
            opacity: 0.6;
            &:hover {
                opacity: 1;
            }
        }
        span {
            margin: 0px 5px;
            opacity: 0.6;
        }
    }
    .logo {
        width: 350px;
        margin-bottom: 25px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.btn-circle {
    width: 138px;
    height: 138px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 23px;
    background-color: #153862;
    color: #fff;
    border-color: #fff;
    text-decoration: none;
    padding: 10px;
    text-align: center;
    border: 1px solid $blue;
    border-radius: 50%;
    position: relative;
    padding-top: 20px;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-name: fly;
    transition: all 0.3s ease;
    &::after {
        content: '';
        position: absolute;
        left: 8px;
        right: 8px;
        top: 8px;
        bottom: 8px;
        border-radius: 50%;
        background-size: 100%;
        background-repeat: no-repeat;
        filter: brightness(0) invert(1);
        background-image: url("data:image/svg+xml,%3Csvg width='121' height='121' viewBox='0 0 121 121' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='60.4835' cy='60.0914' r='59.4725' stroke='%23153862' stroke-dasharray='10 10'/%3E%3C/svg%3E%0A");
    }
    &:hover {
        animation: none;
        border-color: #153862;
        background-color: transparent;
        color: #153862;
        &::after {
            filter: brightness(1) invert(0);
        }
    }
}

@keyframes fly {
    0% {
        transform: translateY(0px);
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
    }
    50% {
        transform: translateY(-10px);
        box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.3);
    }
    100% {
        transform: translateY(0px);
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
    }
}

.btn-mob {
    position: relative;
    width: 21px;
    height: 12px;
    margin-right: 15px;
    span {
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transition: all 0.2s;
        &:nth-child(1) {
            top: 0;
        }
        &:nth-child(2) {
            top: 50%;
            margin-top: -1px;
        }
        &:nth-child(3) {
            bottom: 0;
        }
    }
}

.open-menu {
    overflow: hidden;
    .btn-mob {
        span {
            &:nth-child(1) {
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
            }
            &:nth-child(2) {
                transition: all 0.1s;
                opacity: 0;
            }
            &:nth-child(3) {
                bottom: 50%;
                transform: translateY(50%) rotate(-45deg);
            }
        }
    }
}

[data-aos='img-slide'] {
    overflow: hidden;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #f2f2f2;
    }
}

[data-aos='img-slide'].aos-animate {
    &::after {
        transition: transform 1.2s cubic-bezier(0.2, 0.64, 0.24, 0.99);
        transform: translateY(-100%);
    }
}

.back-btn {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ba4921;
    font-size: 21px;
    font-family: $title;
    .icon {
        width: 21px;
        height: 12px;
        margin-right: 12px;
        position: relative;
        left: 0;
        transition: all 0.3s;
    }
    &:hover {
        .icon {
            left: -5px;
        }
    }
}

.breadcrumbs {
    padding: 30px 0px;
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        font-weight: 400;
        font-size: 12px;
        li {
            margin: 0px 7px;
        }
        a {
            opacity: 0.6;
            text-decoration: none;
            color: inherit;
            &:hover {
                opacity: 1;
            }
        }
    }
}

.text {
    padding-bottom: 60px;
    h1 {
        font-family: $title;
        font-weight: 600;
        font-size: 54px;
        line-height: 60px;
    }
    ul,
    ol {
        padding-left: 20px;
        li {
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}