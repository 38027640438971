@media only screen and (max-width: 1600px) {
  .production .wrapper::after {
    width: 95vw;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
  }
}
@media only screen and (max-width: 1200px) {
  .wrapper {
    max-width: 850px;
  }
  .top-video {
    &__logo {
      width: 450px;
      margin-bottom: 15px;
    }
    &__text-bg,
    .tel {
      font-size: 26px;
    }
    &__svg {
      right: -120px;
      bottom: 76px;
      width: 280px;
    }
  }
  .products .item-product {
    margin: 20px;
    width: calc(16.66666% - 40px);
  }
  .production {
    .swiper-slide {
      height: 510px;
    }
    &__img-work {
      position: absolute;
      right: 0;
      bottom: -40px;
      z-index: 1;
      width: 250px;
    }
  }
  .for-you {
    padding-bottom: 0;
    &__list {
      width: 100%;
      padding-right: 0;
      margin-bottom: 30px;
    }
    &__video {
      width: 100%;
    }
    &::after {
      height: 600px;
      bottom: 135px;
    }
  }
  .orders {
    &__grid {
      gap: 20px;
      grid-template-rows: repeat(4, 125px);
    }
  }
  .form-section {
    .image-center {
      display: none;
    }
    .image-right {
      width: 50%;
    }
    &__col-form {
      max-width: 385px;
    }
    .title-row__img {
      display: none;
    }
    &__contact {
      flex-direction: column;
      align-items: flex-start;
      .soc {
        margin-top: 10px;
      }
    }
  }
  .item-info {
    width: 100%;
    padding: 20px;
  }
  .not-found .title-row {
    width: 505px;
  }
  .not-found h1 {
    font-size: 135px;
    line-height: 150px;
  }
  .not-found__img {
    width: 340px;
    height: 275px;
  }
}
@media only screen and (max-width: 991px) {
  .title-row__img {
    bottom: 20px;
  }
  .header {
    .tel {
      margin-right: 20px;
      .icon {
        width: 25px;
        height: 25px;
      }
    }
    &__bot {
      .tel {
        font-size: 16px;
      }
    }
    .logo {
      width: 200px;
    }
  }
  .wrapper {
    max-width: 670px;
  }
  .top-video__text-bg,
  .top-video .tel {
    font-size: 22px;
  }
  .top-video .tel .icon {
    width: 35px;
    height: 35px;
  }
  .top-video__svg {
    position: absolute;
    right: -90px;
    bottom: 70px;
    width: 280px;
  }
  .last-section .title-row__img {
    display: none;
  }
  .products {
    padding-bottom: 100px;
    .item-product {
      margin: 20px;
      width: calc(25% - 40px);
    }
  }

  .production {
    .swiper-slide {
      height: 411px;
    }
  }

  .orders__swiper-grid-desctop {
    margin-top: 15px;
  }
  .form-section {
    padding: 100px 0px;
    .wrapper__full {
      display: flex;
      flex-direction: column-reverse;
      max-width: 670px;
    }
    .image-right {
      width: 100%;
      position: relative;
      transform: none;
      border-radius: 10px;
      margin-top: 40px;
      max-width: 100%;
    }
    &__col-form {
      width: 100%;
      max-width: 100%;
      .form-idea {
        width: 100%;
      }
    }
    &__contact {
      p {
        max-width: 100%;
      }
      .soc {
        margin-top: 20px;
      }
    }
  }
  .orders__grid {
    gap: 10px;
    grid-template-rows: repeat(4, 90px);
  }
  .popup {
    max-width: 600px;
  }
  .image-parallax__horizontal .img-wrap {
    width: 405px;
    height: 260px;
  }
  .image-parallax__horizontal .image-parallax__bg {
    left: -20px;
    width: 374px;
    height: 330px;
  }
  .image-parallax__vertical .img-wrap,
  .image-parallax__vertical .image-parallax__bg {
    width: 225px;
    height: 310px;
  }
  .image-parallax__vertical .image-parallax__bg {
    bottom: -20px;
    right: -20px;
  }
  .not-found__row {
    flex-direction: column-reverse;
  }
  .not-found .title-row {
    width: 100%;
  }
  .not-found__img {
    width: 100%;
    height: 350px;
  }
  .not-found__text {
    width: 530px;
  }
  .not-found {
    h1 {
      margin: 0px auto;
    }
    .btn {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
@media only screen and (max-width: 750px) {
  .breadcrumbs {
    padding: 20px 0px;
  }
  .pin-top {
    top: -25px;
  }
  .pin-bot {
    bottom: -25px;
  }
  .btn {
    font-size: 14px;
    padding: 0px 35px;
    height: 40px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .wrapper {
    width: 100%;
    max-width: 100%;
    padding: 0px 15px;
  }
  body {
    font-size: 14px;
  }
  .title-row p {
    font-size: 12px;
  }
  .title-right {
    h2 {
      text-align: right;
    }
  }
  .header {
    background-color: $blue;
    padding: 13px 0px;
    &__desctop {
      display: none;
    }
    &__mob {
      display: block;
      .logo {
        width: 148px;
        display: block;
      }
      .col {
        display: flex;
        align-items: center;
      }
      .row {
        justify-content: space-between;
      }
      .phone,
      .email {
        width: 25px;
        height: 25px;
        i {
          width: 100%;
          height: 100%;
        }
      }
      .email {
        margin-left: 17px;
      }
    }
  }
  .title-row__img {
    right: 0;
  }
  h2,
  h3 {
    font-size: 26px;
    line-height: 30px;
  }
  .title-row {
    h2,
    h3 {
    }
    margin-bottom: 13px;
  }
  .footer .logo {
    width: 250px;
  }
  .products__row {
    margin: 0;
  }
  .open-menu {
    .mobile-menu {
      opacity: 1;
      pointer-events: unset;
    }
  }
  .mobile-menu {
    display: block;
    background-color: #fff;
    padding: 30px 15px;
    position: absolute;
    left: 0;
    right: 0;
    top: 51px;
    bottom: 0;
    z-index: 100;
    font-size: 16px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    .lang {
      font-size: 16px;
      margin-bottom: 30px;
      color: #153862;
      a,
      span {
        color: $blue;
      }
    }
    &__links {
      padding: 0;
      margin: 0;
      list-style: none;
      color: inherit;
      margin-bottom: 74px;
      li {
        margin-bottom: 30px;
        a {
          color: inherit;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__contacts {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-top: 30px;
      a {
        color: inherit;
        line-height: 19px;
        font-size: 14px;
        text-decoration: none;
      }
    }
  }

  .top-video {
    height: 400px;
    min-height: unset;
    &__logo {
      width: 227px;
    }
    &__svg {
      right: 0;
    }
    &__text-bg {
      padding: 3px 10px;
    }
    &__text-bg,
    .tel {
      font-size: 14px;
    }
    .tel {
      margin-right: 20px;
      .icon {
        width: 25px;
        height: 25px;
      }
    }
    .soc .icon {
      width: 30px;
      height: 26px;
    }
  }
  .products {
    background-image: url("data:image/svg+xml,%3Csvg width='320' height='854' viewBox='0 0 320 854' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-3.05927 39.9589C-11.1147 40.305 -8.5567 47.4587 -21 45.582V74.3463H334V8.98867C324.747 8.98867 302.774 6.46009 296.23 2.93052C288.051 -1.48145 264.876 2.02217 255.086 9.85124C245.295 17.6803 217.411 13.9629 183.331 16.4717C149.25 18.9805 167.22 16.1689 156.438 16.4717C145.656 16.7745 146.648 18.3749 134.131 19.2832C121.614 20.1916 126.075 18.9805 116.161 22.4408C106.247 25.9012 103.644 25.5552 87.5332 30.2267C71.4224 34.8981 70.4309 28.9723 57.0466 30.2267C43.6622 31.481 46.2647 35.893 29.2863 39.9589C12.308 44.0249 4.99615 39.6129 -3.05927 39.9589Z' stroke='%23F2F2F2' stroke-width='2' stroke-dasharray='8 8'/%3E%3Cpath d='M-3.05908 46.2878C-11.1145 46.6611 -5.6616 51.3285 -19.1699 52.3554V854L332.17 853.996V12.8693C322.917 12.8693 302.774 11.9171 296.231 8.10852C288.051 3.34778 264.877 7.12836 255.086 15.5763C245.296 24.0243 217.412 18.2368 183.331 20.9438C149.25 23.6509 167.22 20.6171 156.438 20.9438C145.656 21.2706 146.648 22.9975 134.131 23.9776C121.614 24.9578 126.075 23.6509 116.161 27.3848C106.247 31.1187 103.644 30.7453 87.5334 35.7861C71.4226 40.8269 70.4311 34.4326 57.0467 35.7861C43.6623 37.1397 46.2649 41.9004 29.2865 46.2878C12.3082 50.6751 4.99633 45.9144 -3.05908 46.2878Z' fill='%23F2F2F2'/%3E%3C/svg%3E%0A");
    padding-bottom: 56px;
    padding-top: 75px;
    background-position: top;
    background-color: transparent;
    background-size: cover;
    margin-top: -75px;
    .bg {
      display: none;
    }
    &__row {
      margin: -10px;
    }
    .item-product {
      margin: 10px;
      width: calc(33.3333% - 20px);
    }
    .title-row {
      margin-bottom: 20px;
      p {
        max-width: 190px;
      }
    }
  }
  .production {
    padding-top: 54px;
    padding-bottom: 117px;
    .pin-bot {
      bottom: -104px;
    }
    h2 {
      max-width: 200px;
    }
    .wrapper {
      padding-bottom: 36px;
      &::after {
        width: 100vw;
        border-radius: 0;
        height: 180px;
      }
    }
    .swiper-slide {
      gap: 10px;
      height: 220px;
    }
    &__swiper {
      padding-top: 36px;
      margin-top: -36px;
    }
    .swiper-slide .item-slider.item-slider-text {
      padding: 6px;
      font-size: 11px;
      line-height: 14px;
      img {
        width: 19px;
        height: 19px;
        margin-bottom: 8px;
      }
      ul li {
        margin-bottom: 10px;
      }
    }
    &__img-work {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      bottom: -40px;
      width: 170px;
    }
  }
  .orders {
    padding-bottom: 52px;
    padding-top: 54px;
    .title-row h2 {
      margin-bottom: 8px;
    }
    .icons-row {
      margin-bottom: 10px;
      img {
        margin: 0px 7px;
        width: 17px;
        height: 17px;
        object-fit: contain;
      }
    }
    &__swiper-grid-desctop {
      display: none;
    }
    &__swiper-grid-mobile {
      display: block;
      position: relative;
      padding-top: 30px;
      margin-top: 10px;
      .swiper-slide {
        height: 150px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }
      .wrap-btn {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .btn {
      margin-top: 20px;
    }
  }
  .form-section {
    padding: 50px 0px;
    .title-row {
      margin-bottom: 25px;
    }
    .image-right {
      width: calc(100% - 30px);
      margin-top: 20px;
      height: 290px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .info {
    padding: 40px 0px 90px;
    h2 {
      max-width: 200px;
    }
    &__swiper {
      padding-top: 35px;
    }
  }
  .item-info {
    &__img {
      height: 220px;
    }
    &__title {
      font-size: 16px;
      line-height: 20px;
      height: 60px;
    }
  }
  .pin-top,
  .pin-bot {
    width: 5px;
    height: 49px;
    object-fit: cover;
    object-position: bottom;
  }
  .for-you {
    margin-bottom: 50px;
    &::after {
      height: 370px;
      bottom: 165px;
    }
    &__video video {
      height: 250px;
    }
    .title-row {
      margin-bottom: 40px;
    }
    &__list {
      max-width: 350px;
      ul li {
        padding-left: 25px;
        span {
          margin-left: 25px;
        }
        &::after {
          width: 7px;
          height: 7px;
        }
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  .last-section {
    &__icon {
      width: 65px;
      height: 65px;
      margin-top: -33px;
    }
    .title-row {
      margin-top: 25px;
      margin-bottom: 10px;
    }
  }
  .footer {
    background-image: url("data:image/svg+xml,%3Csvg width='320' height='461' viewBox='0 0 320 461' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-106.797 95.5978C-118.399 96.4843 -110.545 107.566 -130 110.005V580H376V16.2499C362.673 16.2499 333.664 13.9891 324.24 4.94611C312.46 -6.35763 279.084 2.61887 264.983 22.6775C250.883 42.7361 210.725 28.9943 161.642 35.4219C112.559 41.8495 138.439 34.6462 122.911 35.4219C107.383 36.1977 108.811 40.298 90.7838 42.6253C72.757 44.9525 79.1824 41.8495 64.9037 50.7152C50.625 59.5809 46.8769 58.6943 23.6741 70.663C0.471252 82.6317 -0.956619 67.4492 -20.2328 70.663C-39.509 73.8768 -35.7608 85.1806 -60.2131 95.5978C-84.6653 106.015 -95.1958 94.7112 -106.797 95.5978Z' fill='%23153862'/%3E%3Cpath d='M-107.442 106.876C-119.078 107.793 -116.488 119.255 -136 121.777V628H390V24.8071C376.634 24.8071 334.322 22.4688 324.871 13.1157C313.056 1.42435 279.581 10.7087 265.439 31.4551C251.297 52.2016 211.019 37.9886 161.791 44.6366C112.563 51.2847 138.52 43.8343 122.946 44.6366C107.372 45.439 108.804 49.68 90.7236 52.087C72.6434 54.4941 79.0879 51.2847 64.7669 60.4544C50.446 69.6241 46.6868 68.7071 23.4153 81.0862C0.143799 93.4653 -1.2883 77.7622 -20.6215 81.0862C-39.9548 84.4102 -36.1955 96.1017 -60.7201 106.876C-85.2447 117.65 -95.8063 105.959 -107.442 106.876Z' stroke='%23F2F2F2' stroke-width='2' stroke-dasharray='10 10'/%3E%3C/svg%3E%0A");
    height: auto;
    padding-top: 130px;
    margin-top: 100px;
    .wrapper {
      position: static;
    }
    .btn-circle {
      top: -75px;
      left: calc(50% - 44px);
      font-size: 12px;
      line-height: 16px;
    }
    a {
      font-size: 14px;
      line-height: 19px;
    }
    &__bot {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__bot-links {
      margin-top: 23px;
    }
    .soc .icon {
      width: 20px;
      height: 20px;
    }
    &__bot {
      padding-bottom: 30px;
    }
  }
  .btn-circle {
    width: 88px;
    height: 88px;
    font-size: 12px;
    line-height: 16px;
    padding-top: 13px;
  }
  .title-row.title-right .title-row__img {
    left: -75px;
    top: 15px;
    bottom: auto;
  }
  .top-video__svg {
    background-image: url("data:image/svg+xml,%3Csvg width='131' height='32' viewBox='0 0 131 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M90.5 2.99998C91.1205 2.99998 91.6531 2.62317 91.8813 2.08589L116.193 2.08588C123.761 2.08588 129.896 8.2209 129.896 15.7888C129.896 23.3567 123.761 29.4917 116.193 29.4917L2.91519 29.4918C2.7097 28.9084 2.15368 28.4902 1.5 28.4902C0.671576 28.4902 3.31489e-06 29.1618 3.40906e-06 29.9902C3.50323e-06 30.8187 0.671576 31.4902 1.5 31.4902C2.15254 31.4902 2.70776 31.0736 2.91411 30.4918L116.193 30.4917C124.314 30.4917 130.896 23.909 130.896 15.7888C130.896 7.66861 124.313 1.08588 116.193 1.08589L91.9421 1.08589C91.7624 0.458854 91.1848 -2.45232e-05 90.5 -2.4339e-05C89.6716 -2.41162e-05 89 0.671549 89 1.49998C89 2.3284 89.6716 2.99998 90.5 2.99998Z' fill='white'/%3E%3C/svg%3E%0A");
    width: 130px;
    height: 32px;
    bottom: 55px;
  }
  .products .title-line {
    background-image: url("data:image/svg+xml,%3Csvg width='86' height='36' viewBox='0 0 86 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M49.4665 2.98126C48.7608 2.98126 48.1697 2.49091 48.0152 1.83236L16.7344 1.83236C8.04456 1.83236 1.00005 8.87686 1.00004 17.5667L1.0002 17.5667C1.0002 26.2565 8.04471 33.301 16.7345 33.301L71.9384 33.301L82.2022 33.301C82.3726 32.6653 82.9527 32.1973 83.6422 32.1973C84.4654 32.1973 85.1328 32.8646 85.1328 33.6879C85.1328 34.5112 84.4654 35.1785 83.6422 35.1785C83.0375 35.1785 82.5169 34.8185 82.2831 34.301L71.9384 34.301L16.7345 34.301C7.49241 34.301 0.000196021 26.8088 0.000196829 17.5667L4.42406e-05 17.5667C4.50486e-05 8.32458 7.49226 0.832355 16.7344 0.832356L48.1287 0.832359C48.3718 0.339244 48.8795 -3.16936e-06 49.4665 -3.11805e-06C50.2897 -3.04608e-06 50.9571 0.667376 50.9571 1.49063C50.9571 2.31388 50.2897 2.98126 49.4665 2.98126Z' fill='%23153862'/%3E%3C/svg%3E%0A");
    width: 85px;
    height: 35px;
  }
  .for-you {
    h2 {
      max-width: 290px;
    }
    .title-line {
      background-image: url("data:image/svg+xml,%3Csvg width='86' height='36' viewBox='0 0 86 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M35.6663 2.98126C36.372 2.98126 36.9631 2.49091 37.1176 1.83236L68.3984 1.83236C77.0883 1.83236 84.1328 8.87686 84.1328 17.5667L84.1326 17.5667C84.1326 26.2565 77.0881 33.301 68.3983 33.301L13.1945 33.301L2.93058 33.301C2.76021 32.6653 2.18009 32.1973 1.49064 32.1973C0.667382 32.1973 2.87312e-06 32.8646 2.94509e-06 33.6879C3.01706e-06 34.5112 0.667382 35.1785 1.49064 35.1785C2.09534 35.1785 2.61594 34.8185 2.84974 34.301L13.1945 34.301L68.3983 34.301C77.6404 34.301 85.1326 26.8088 85.1326 17.5667L85.1328 17.5667C85.1328 8.32458 77.6405 0.832355 68.3984 0.832356L37.0041 0.832359C36.761 0.339244 36.2533 -3.16936e-06 35.6663 -3.11805e-06C34.8431 -3.04608e-06 34.1757 0.667376 34.1757 1.49063C34.1757 2.31388 34.8431 2.98126 35.6663 2.98126Z' fill='%23153862'/%3E%3C/svg%3E%0A");
      width: 85px;
      height: 35px;
      bottom: -20px;
      left: 95px;
    }
  }
  .mfp-container {
    padding: 0;
  }
  .popup {
    padding: 50px 15px;
    width: calc(100% - 30px);
    .popup__form h4 {
      margin-bottom: 4px;
    }
    h4 {
      font-size: 21px;
      line-height: 25px;
    }
    p {
      font-size: 12px;
      max-width: 250px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  #popup-thank {
    .btn {
      margin-top: 38px;
    }
    h4 {
      margin-bottom: 14px;
    }
  }
  .mfp-close-btn-in .mfp-close {
    font-size: 40px;
  }
  .image-parallax {
    flex-direction: column;
    margin: 70px 0px;
  }

  .article {
    .back-btn {
      left: 15px;
    }
  }
  .back-btn {
    font-size: 14px;
  }
  .image-parallax__horizontal {
    margin-bottom: 60px;
  }

  .image-parallax__horizontal .image-parallax__bg {
    left: 0;
    height: 270px;
    width: 65%;
  }
  .image-parallax__horizontal .img-wrap {
    width: 90%;
    padding-left: 25px;
    height: 210px;
  }
  .image-parallax__vertical .img-wrap,
  .image-parallax__vertical .image-parallax__bg {
    width: 65%;
    margin: 0 auto;
  }
  .image-parallax__vertical .image-parallax__bg {
    bottom: -35px;
    right: 0;
  }
  .not-found .title-row__img {
    bottom: 22px;
  }
  .not-found {
    h1 {
      font-size: 90px;
      margin: 0px auto 10px;
      line-height: 105px;
    }
    .btn {
      width: 100%;
      margin-top: 35px;
    }
  }
  .not-found__img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  .not-found__text p {
    max-width: 180px;
    margin-right: auto;
    margin-left: 100px;
  }
  .not-found__text {
    width: 100%;
  }
  .not-found .title-line {
    width: 85px;
    height: 35px;
    background-image: url("data:image/svg+xml,%3Csvg width='86' height='36' viewBox='0 0 86 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M49.4665 2.98126C48.7608 2.98126 48.1697 2.49091 48.0152 1.83236L16.7344 1.83236C8.04456 1.83236 1.00005 8.87686 1.00004 17.5667L1.0002 17.5667C1.0002 26.2565 8.04471 33.301 16.7345 33.301L71.9384 33.301L82.2022 33.301C82.3726 32.6653 82.9527 32.1973 83.6422 32.1973C84.4654 32.1973 85.1328 32.8646 85.1328 33.6879C85.1328 34.5112 84.4654 35.1785 83.6422 35.1785C83.0375 35.1785 82.5169 34.8185 82.2831 34.301L71.9384 34.301L16.7345 34.301C7.49241 34.301 0.000196021 26.8088 0.000196829 17.5667L4.42406e-05 17.5667C4.50486e-05 8.32458 7.49226 0.832355 16.7344 0.832356L48.1287 0.832359C48.3718 0.339244 48.8795 -3.16936e-06 49.4665 -3.11805e-06C50.2897 -3.04608e-06 50.9571 0.667376 50.9571 1.49063C50.9571 2.31388 50.2897 2.98126 49.4665 2.98126Z' fill='%23153862'/%3E%3C/svg%3E%0A");
  }
  .not-found + .footer {
    margin-top: 40px;
  }
  .text {
    padding-bottom: 40px;
    h1 {
      font-size: 32px;
      line-height: 36px;
    }
  }
}
